export function useJobFiltersWithValues({ filters, values }) {
  return React.useMemo(
    () => {
      return {
        job_branch: optionsWithLabelAndValue('job_branch', filters, values),
        remote_type: optionsWithLabelAndValue('remote_type', filters, values),
        country: optionsWithLabelAndValue('country', filters, values),
        contract_type: optionsWithLabelAndValue('contract_type', filters, values),
      }
    }, [filters, values]
  )
}

export function useTechblogFiltersWithValues({ filters, values }) {
  return {
    // category: optionsWithLabelAndValue('category'), // Do not show category in filters list
    tag: optionsWithLabelAndValue('tag', filters, values),
  }
}

export function useTopicFiltersWithValues({ filters, values }) {
  return {
    tag: optionsWithLabelAndValue('tag', filters, values),
  }
}

function optionsWithLabelAndValue(filterName, filters, values) {
  const filterData = filters.find(x => x.id === filterName)
  const value = values[filterName]

  return { ...filterData, value }
}
